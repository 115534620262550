import logo from './logo.svg';
import './App.css';
import { useState } from 'react';
import React from 'react';
import Chat from "./components/Chat";

function Sending(){

}

function App() {
  

  return (
    <div>    
      <Chat />
    </div>
  );
}

export default App;
