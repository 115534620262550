import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import $ from "jquery";

import styles from "../styles/Chat.module.css";
import Messages from "./Messages";
var textMessage = '';
const Chat = () => {
    const [stateButton, setButton] = useState(true);
    const [stateKind, setKind] = useState('i');
    const [stateVisiblePlace, setVisiblePlace] = useState(true);
    const [stateVisibleCat, setVisibleCat] = useState(false);
    const [statePlace, setPlace] = useState("");
    const [stateFirstAnswer, setAnswers] = useState('');


    function sendText(textMes, cat, worker){
        // const data = {
        //     name: 'textMes',
        //   };
        if (textMes == 'start'){
            textMes = null;
        }
              $.ajax({
        // url: 'http://localhost:8888/basic/web/index.php?r=post%2Findex',
    //    url: 'http://localhost:8888/basic/web/post/ajax', //LOCAL HOST
       url: 'http://localhost:8888/harmony-cdn/web/chatbot/ajax', //SCHOOL CDN
        
        // url: 'http://tatarin.site/back/web/post/ajax', //HOST
        type: 'post',
        dataType: 'json',
        data: {param1: textMes, param2:cat, param3:worker},
        success: function(res){
            console.log(res.text);
            console.log(res.type);
            console.log(res.kind);
            setKind(res.kind);
            setState((_state) => [..._state, {user: "admin", message: res.text}])
            if (res.type == "Конец"){
                console.log('End');
                // setState((_state) => [..._state, {user: "admin", message: res.text}])
                sendText('123', null);
                setVisiblePlace(true);
            } else if (res.type == 'Strict'){
                setButton(false);
            } else if (res.type == 'YesNo'){
                setButton(true);
            }
        },
        error: function(er){
            console.log('error');
            console.log(er.responseText);
            // textMessage = er.responseText;
            // setState((_state) => [..._state, {user: "admin", message: er.responseText}])
        }
});
   



    //     fetch('http://tatarin.site/back/web/post/ajax', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json'
    //     },
    //     // body: JSON.stringify({params1: "privet"})
    //     body: JSON.stringify("privet")
    //   })
    //   .then(response => {
    //     if (response.ok) {
    //       console.log('Data sent successfully!');
    //       response.text().then(value => {
    //         console.log(value);
    //       });
    //     } else {
    //       console.log('Failed to send data');
    //     }
    //   })
    //   .catch(error => {
    //     console.error('Error:', error);
    //   });

    


    // const resp = fetch('http://localhost:8888/basic/web/post/ajax', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify(data)
    //   })
    //   console.log(resp.json());

        // fetch("http://tatarin.site/web/index.php?r=site%2Findex",{
        //   method : 'POST',
        //   header : {
        //     'Content-Type': 'application/x-ww-form-urlencode',
        //   },
        //   body : JSON.stringify({action : 1})
        // })
        // .then (response => response.text())
        // .then (response => {
        //   console.log(response);
        // })
    //     $.ajax({
    //       type: "POST",
    //       // url: 'http://tatarin.site/web/index.php?r=site%2Findex',
           
    //       // url: 'http://localhost:8888/testYii2/web/index.php?r=site%2Findex',
    //       data: {test: '123'},
    //       mode: 'no-cors',
    //       success() {
    //           console.log('HelloBud');
    //       },
    //       error(){

    //       }
    //   });
      }
    function sendCat(cat){
        $.ajax({
            // url: 'http://localhost:8888/basic/web/index.php?r=post%2Findex',
        //    url: 'http://localhost:8888/basic/web/post/cat',
            
            url: 'http://tatarin.site/back/web/post/ajax',
            type: 'post',
            dataType: 'json',
            data: {param1: cat},
            success: function(res){
                console.log(res.text);
            },
            error: function(er){
               
            }
    });

    }
      
    const [state, setState] = useState([{user: "admin", message: "Добрый день,(имя пользователя). Вас приветствует виртуальный помощник школы Гармония. Рад вас видеть.  Я вам помогу оставить заявку на устранение неисправности. Укажите место неисправности."}
]);
    
    const [message, setMessage] = useState("");
    
    const handleChange = ({target: {value}}) => {
        setMessage(value);
      
      };
      
    const handleSubmit = (e) => {
      alert('sdfsd');
       
        e.preventDefault();
      
        if (!message) {
            console.log('end');
            return;};
        console.log('yes');
      
      };
    const OutPut = (e) => {
        // console.log('Pressed');
        e.preventDefault();
      
        if (!message) {
            console.log('end');
            return;};
        
        setState((_state) => [..._state, {user: "user", message: message }])
        if (stateKind == "кабинет"){
            if (Number(message)>9999 || Number(message)<1000){
                setState((_state) => [..._state, {user: "admin", message: "неверный кабинет" }])
                setMessage("");
                return
            }
        }
        sendText(message, null);
        setMessage("");
    }
    const startAction = (e) =>{
        e.preventDefault();
      
        // if (!message) {
        //     return;};
        setState((_state) => [..._state, {user: "user", message: "start" }])
        sendText("start", null);
    }


    const yesAction = (e) =>{
        e.preventDefault();
      
        // if (!message) {
        //     return;};
        setState((_state) => [..._state, {user: "user", message: "Да" }])
        sendText("Да", null);
    }
    const noAction = (e) =>{
        e.preventDefault();
      
        // if (!message) {
        //     return;};
        setState((_state) => [..._state, {user: "user", message: "Нет" }])
        sendText("Нет", null);
    }


    const listOfCat = [
        {id:0, name:"Неисправность в электросети", type:"Категория",idChange:5, worker:"Электрик"},
        {id:1, name:"Неисправность в канализации", type:"Категория",idChange:107,worker:"Сантехник"},
        {id:2, name:"Неисправность в водоснабжении", type:"Категория",idChange:108,worker:"Сантехник"},
        {id:3, name:"Неисправность в отоплении", type:"Категория",idChange:109,worker:"Сантехник"},
        {id:4, name:"Ит и ОРГ техника", type:"Категория",idChange:191,worker:"ИТ отдел"},
        {id:5, name:"Общеремонтные работы", type:"Категория",idChange:232,worker:"СтройРаб"},
        {id:6, name:"Неисправность в вентеляции", type:"Категория",idChange:110,worker:"СтройРаб"},
    ]
    const listOfPlace = [
        {id:0, name:"Кабинет, столовая, медпункт", type:"Место", idChange:1},
        {id:1, name:"Туалет", type:"Место",idChange:3},
        {id:2, name:"Коридор", type:"Место",idChange:4},
    ]
    const chatbuttonHandler = (task) => {
        if (task.type == 'Место'){
            setVisiblePlace(false);
            setVisibleCat(true);
            // setPlace(true);
        } else {
            setVisibleCat(false);
            setVisiblePlace(false);
        }
        setState((_state) => [..._state, {user: "user", message: task.name }])
        if (task.type == "Место"){
            setPlace(task.name)
            // setState((_state) => [..._state, {user: "admin", message: "Выбирите неисправность" }])
            sendText('start', task.idChange, null);    
        } else {
            if (statePlace == "Туалет"){
                switch (task.id){
                    case 1:
                        sendText('start', 253, task.worker);
                        break;
                    case 2:
                        sendText('start', 301, task.worker);
                        break;
                }
            } else {
                sendText('start', task.idChange, task.worker);
            }
        }
    }
      



    return(
<div className={styles.wrap}>
    <div className={styles.messages}>
        <div className={styles.message}>
            <Messages messages={state} name={state} />
        </div>
        {stateVisiblePlace && <div>
        {listOfPlace.map((task) => (
          <button className={styles.buttonChat} 
          key={task.id} 
          onClick={() => chatbuttonHandler(task)
          }>
            {task.name}
          </button>
        ))}
        </div>}
        {stateVisibleCat && <div>
        {listOfCat.map((task) => (
          <button className={styles.buttonChat} 
          key={task.id} 
          onClick={() => chatbuttonHandler(task)
          }>
            {task.name}
          </button>
        ))}
        </div>}
       
        <form className={styles.form}>
            <div className={styles.input}>
                <input
                    type="text"
                    name="message"
                    placeholder="Write"
                    value={message}
                    onChange={handleChange} />
            </div>
            <div className={styles.button}>
            {/* <input type="submit" onSubmit={handleSubmit} value="Отправить" /> */}
            <button onClick={OutPut} className={styles.buttonTwo}>Отправить</button>
        </div>
        </form>
        <div className={styles.button}>
            {/* <input type="submit" onSubmit={handleSubmit} value="ОТправить" /> */}
            <button onClick={startAction} className={styles.buttonStart}>start</button>
            <button disabled={!stateButton} onClick={yesAction} className={styles.buttonStart}>Да</button>
            <button disabled={!stateButton} onClick={noAction} className={styles.buttonStart}>Нет</button>
        </div>
    </div>
</div>

    )
}

export default Chat;